<template>
    <modal-lateral ref="modalFiltro" titulo="Filtro">
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 103px)">
            <div class="row mx-0 px-4 justify-center mb-3">
                <div class="col-12 px-0">
                    <p class="text-general f-14 pl-3">Responsable</p>
                    <el-select v-model="responsable" filterable clearable class="w-100">
                        <el-option
                        v-for="item in responsables"
                        :key="item.id"
                        :label="item.nombre"
                        :value="item.id"
                        />
                    </el-select>
                </div>
            </div>
            <div class="row mx-0 mb-3 px-4">
                <div class="col-12 bg-light-f5 cr-pointer d-middle border br-8 " style="height:32px;">
                    <span class="f-14 text-general">Cajas</span>
                    <div class="rounded-circle text-white" style="width:20px;height:20px;">
                        1
                    </div>
                </div>
                <template>
                    <div v-for="(caja,c) in cajas" :key="c" class="col-12 mt-3 pl-4">
                        <el-checkbox v-model="caja.checked" class="check-dark mr-2" />
                        {{ caja.nombre }}
                    </div>
                </template>
            </div>
        </div>
        <div class="row mx-0">
            <div class="col">
                <div class="bg-white cr-pointer text-general border-black d-middle-center br-8" style="height:32px;" @click="limpiar">
                    Limpiar
                </div>
            </div>
            <div class="col">
                <div class="bg-general cr-pointer text-white br-8 d-middle-center" style="height:32px;" @click="filtrar">
                    Filtrar
                </div>
            </div>
        </div>
    </modal-lateral>
</template>

<script>
import Movimientos from "~/services/flujoDinero/movimientos";
export default {
    data(){
        return {
            responsables: [],
            responsable: null,
            cajas: [],
        }
    },
    computed: {
        filtro(){
            return (this.responsable || this.cajasFiltradas.length > 0) ? 1 : 0
        },
        cajasFiltradas(){
            return this.cajas.filter(el => el.checked).map(({id}) => id)
        }
    },
    methods: {
        toggle(filtro){
            if (!filtro){
                this.filtroMovimientos()
            }
            this.$refs.modalFiltro.toggle();
        },
        async filtroMovimientos(){
            try {
                const { data } = await Movimientos.filtroMovimientos()
                this.responsables = data.data.responsables
                data.data.cajas.map(el =>
                    el.checked = false
                )
                this.cajas = data.data.cajas
            } catch (error){
                this.error_catch(error)
            }
        },
        limpiar(){
            this.cajas.map(el =>
                el.checked = false
            )
            this.responsable = null
            this.filtrar()
        },
        filtrar(){
            const params = {
                responsable: this.responsable,
                cajas: this.cajasFiltradas,
                filtro: this.filtro
            }
            console.log({params});
            this.$emit('filtrar', params)
            this.$refs.modalFiltro.toggle();

        },
    }
}
</script>
